import { createContext, ReactNode, useEffect, useState } from 'react'
import { Token } from '../api';
import { jwtDecode, JwtPayload } from 'jwt-decode'

type Props = {
  children?: ReactNode;
}

type IAuthContext = {
  user: string | null;
  isSuperuser: boolean;
  isStuffUser: boolean;
  authTokens: string | null;
  loginUser: (token: Token) => void;
  logoutUser: () => void;
}

interface CustomJwtPayload extends JwtPayload {
  is_superuser: boolean
  is_stuff: boolean
}

const token: Token = localStorage.getItem('authTokens')? 
  JSON.parse(localStorage.getItem('authTokens') as string) : null

const initialValue: IAuthContext = {
  user: token? jwtDecode<JwtPayload>(token.access_token).sub as string : null,
  isSuperuser: token? jwtDecode<CustomJwtPayload>(token.access_token).is_superuser : false,
  isStuffUser: token? jwtDecode<CustomJwtPayload>(token.access_token).is_stuff     : false,
  authTokens: token? token.access_token : null,
  loginUser: () => {},
  logoutUser: () => {}
}


const AuthContext = createContext<IAuthContext>(initialValue)

export default AuthContext;

export const AuthProvider = ({children}: Props) => {
  const [authTokens, setAuthTokens] = useState<string | null>(initialValue.authTokens)
  const [user, setUser] = useState<string | null>(initialValue.user)
  const [isSuperuser, setIsSuperuser] = useState(initialValue.isSuperuser)
  const [isStuffUser, setIsStuffUser] = useState(initialValue.isStuffUser)
  // const [loading, setLoading] = useState(true)

  // const history = useHistory()

  const loginUser = (token: Token) => {
    const jwtPayload: CustomJwtPayload = jwtDecode(token.access_token)

    setAuthTokens(token.access_token)
    setUser(jwtPayload.sub ? jwtPayload.sub : null)
    setIsSuperuser(jwtPayload.is_superuser ? true : false)
    setIsStuffUser(jwtPayload.is_stuff? true : false)
    localStorage.setItem('authTokens', JSON.stringify(token))
    // history.push('/')
  }

  const logoutUser = () => {
    setUser(null)
    setAuthTokens(null)
    localStorage.removeItem('authTokens')
    // history.push('/login')
  }

  const contextData: IAuthContext = {
    user,
    isSuperuser,
    isStuffUser,
    authTokens,
    loginUser,
    logoutUser,
  }

  // useEffect(() => {
  //   if (localStorage.getItem('authTokens')) {
  //     const token: Token = JSON.parse(localStorage.getItem('authTokens') as string)
  //     loginUser(token)
  //   }
  // } , [])

  useEffect(() => {
    const fourMinutes = 1000 * 60 * 14

    let interval =  setInterval(()=> {
        if(authTokens){
            // updateToken()
            logoutUser()
        }
    }, fourMinutes)
    return ()=> clearInterval(interval)

  }, [authTokens])

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  )
}

