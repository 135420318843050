import React from 'react'
import { Container, Box } from '@mui/material'

const Home = () => {
  return (
    <div>
      <Container>
        <Box
            sx={{
            mt: 5,
            display: "flex",
            flexDirection: "raw",
            alignItems: "center",
            }}
        >
            <h1>Home</h1>
        </Box>
      </Container>
    </div>
  )
}

export default Home
