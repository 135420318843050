import React, { useEffect } from 'react'
import { Box, Container, Grid, Link, Typography, useTheme } from '@mui/material'
import Carousel from 'react-material-ui-carousel';


const SqaixV1 = () => { 
  
  const theme = useTheme();
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setWindowHeight(
      window.innerHeight > 610 ? (window.innerHeight) : (610)
    ));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  }, []);
 
  return (
    <Box sx={{
        backgroundImage: theme.palette.mode === 'dark' ? (
          "url('/images/moon-d.jpg')"
        ) : (
          "url('/images/earth-l.jpg')"
        ),
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        mt: 0,
      }}>
      <Container>
        <Box
          sx={{
            mt: 0,
            mb: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >

          <Grid
            container
            justifyContent="center"
            sx = {{
              width: {xs: 315, sm: 400},
              // mt: {xs: 12, sm: 11 },
              mt: {xs: 12 + 25*(windowHeight/882-1), sm: 11 + 25*(windowHeight/882-1)},
              // mb: {xs: 2, sm: 3},
              mb: {xs: 2 + 25*(windowHeight/882-1), sm: 3 + 25*(windowHeight/882-1)},
            }}
          >
            {theme.palette.mode === 'dark' ? (
                <img src="/images/logo-bt.png" alt='logo-bt' width="100%" />
            ) : (
                <img src="/images/logo-wt.png" alt='logo-wt' width="100%" />
            )}
          </Grid>

        </Box>

        <SqaixNumbers />

        <Grid
          container
          justifyContent="center"
          // sx={{ mt: 10, flexGrow: 1, mb: 2 }}
          sx={{
            // mt: {xs: (windowHeight-315-155+3)/8-20-18, sm: (windowHeight-400-180)/8-8-18},
            mt: {xs: (windowHeight-315-155+3-40)/8-20-18-50*(windowHeight/882-1), sm: (windowHeight-400-180+3-40)/8-8-18-50*(windowHeight/882-1)},
            flexGrow: 1,
            mb: -18,
          }}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Grid item
            sx={{
              mt: 2-35*(windowHeight/882-1),
            }}
          >
            <Typography
              align="center"
              sx={{ mb: 2 + 5*(windowHeight/882-1) }}
            >
              <Link
                href="/SQAIX.pdf" target="_blank"
                underline="hover"
                color="inherit"
                // sx={{ mt: 25*(windowHeight/882-1) + 20}}
                // sx={{ ml: {xs: 3.5, sm: 0}, mr: {xs: 1, sm: 1.5} }}
                sx={{ mr: {xs: 1, sm: 1.5} }}
              >
                {windowWidth < 600 ? ('ABOUT (PDF)') : ('MORE ABOUT SQAIX (PDF)')}
              </Link>
              |
              <Link
                href="/SQAIX-Products.pdf" target="_blank"
                underline="hover"
                color="inherit"
                // sx={{ mt: 25*(windowHeight/882-1) + 20}}
                // sx={{ ml: {xs: 1, sm: 1.5}, mr: {xs: 0, sm: 4} }}
                sx={{ ml: {xs: 1, sm: 1.5} }}
              >
                {windowWidth < 600 ? ('PRODUCTS (PDF)') : ('OUR PRODUCTS (PDF)')}
              </Link>
            </Typography>
            <Typography align="center">
              <Link
                href="mailto:info@sqaix.ai"
                underline="hover"
                color="inherit"
              >
                  info@sqaix.ai
              </Link>
            </Typography>
            <Typography
              sx={{
                mt: 18 + 30*(windowHeight/882-1),
              }}
              textAlign="center"
            >
              {' '}
            </Typography>
          </Grid>
          {/* <Grid item>
              test 2
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  )
}

export default SqaixV1


interface NumberProps {
  key: number,
  item: {
    name: string,
    description: string,
  },
}


function SqaixNumbers()
{
    var items = [
        {
            name: "7",
            description: "Our team consists of 7 highly skilled experts in Artificial Intelligence, Machine Learning, and Numerical & Quantitative Methods."
        },
        {
            name: "100%",
            description: "All of our experts have PhDs and include university professors in the field."
        },
        {
          name: "350+",
          description: "We have delivered more than 350 projects in Artificial Intelligence, Machine Learning, and Numerical & Quantitative Methods."
        },
        {
          name: "1993",
          description: "We are the AI & Quantitative Analytics Unit of Lombriser & Co., developing Software & Hardware Engineering solutions since 1993."
        },
        {
          name: "78",
          description: "Our team has a combined 78 years of experience in Artificial Intelligence, Numerical & Quantitative Methods and Data Science."
        },

    ]

    return (
      <Box alignItems="center">
        <Grid container sx={{ width: '100%' }} justifyContent="center" >
          <Grid sx={{ width: 425 }}>
            <Carousel interval={7000} >
              {
                items.map( (item, i) => <NumberItem key={i} item={item} /> )
              }
            </Carousel>
          </Grid>
        </Grid>
      </Box>
    )
}


function NumberItem(props: NumberProps)
{
    return (
      <Box>
        <Grid container sx={{ width: '100%' }} justifyContent="center" >
          <Grid sx={{ width: { xs: 220, sm: 300 }, height: { xs: 180, sm: 155} }}>
            <Grid container justifyContent="center">
              <Typography variant="h4" color="primary" fontWeight="bold" >
                {props.item.name}
              </Typography>
            </Grid>
            <Grid sx={{ mt: 1.5}}>
              {props.item.description}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
}