import React from 'react';
import { Container, createTheme, ThemeProvider, PaletteMode, CssBaseline, IconButton, Link, Typography, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFoundPage from './pages/NotFoundPage';
import SqaixV1 from './pages/SqaixV1';
import Home from './pages/Home';
import ButtonAppBar from './components/navbar/navbar';
import Login from './pages/Login';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Email } from '@mui/icons-material';


function App() {
  // const [mode, setMode] = React.useState<PaletteMode>('dark');
  const [mode, setMode] = React.useState<PaletteMode>('dark');
  const defaultTheme = createTheme({ palette: { mode,
    ...(mode === 'light'
    ? {
        primary: {
          main: "#272727",
        },
      } : {
        primary: {
          main: "#999999",
        },
      })
    } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const [impressumOpen, setImpressumOpen] = React.useState(false);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <ButtonAppBar title='SQAIX' mode={mode} toggleColorMode={toggleColorMode} />
      <Router>
        <Routes>
          <Route path='/' element={<SqaixV1 />} />
          <Route path='/home' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </Router>
      {/* <hr /> */}
      <Container sx={{
          mt: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        {/* <Alert severity="warning">under construction...</Alert> */}
        <Grid container justifyContent="center" sx={{ mt: 5, mb: 0.5 }}>
          <IconButton href="mailto:info@sqaix.ai">
            <Email />
          </IconButton>
          <IconButton href="https://www.linkedin.com/showcase/102189427" target="_blank" >
            <LinkedInIcon sx={{ color: '#0e76a8' }} />
          </IconButton>
          <IconButton href="https://lom.ch/" target="_blank" >
            {mode === 'dark' ? (
              <img src="/images/LombriserCoIcon-w.png" alt='LombriserCoIcon' width='18' />
            ) : (
              <img src="/images/LombriserCoIcon.png" alt='LombriserCoIcon' width='18' />
            )}
          </IconButton>
          <IconButton size="small" onClick={evt => setImpressumOpen(true)} sx={{ mt: -0.25, ml: 0.25 }}>
            §
          </IconButton>
        </Grid>
        <Typography variant="caption" color="textSecondary">
          © {new Date().getFullYear()}{' '}
          <Link href="https://lom.ch/" underline="hover" color="inherit" target="_blank" >
            Lombriser & Co.
          </Link>
        </Typography>

        <Dialog open={impressumOpen}>
          <DialogTitle>Impressum</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography>
                SQAIX is the Quantitative Analytics & AI unit of{' '}
                <Link href="https://lom.ch/" underline="hover" color="inherit" target="_blank" >
                  Lombriser & Co.
                </Link>
              </Typography>
              <Typography fontWeight="bold" sx={{ mt: 2, mb: 0.25 }}>
                E-mail:
              </Typography>
              <Typography>
                <Link href="mailto:info@sqaix.ai" underline="hover" color="inherit" target="_blank" >
                  info@sqaix.ai
                </Link>
              </Typography>
              <Typography fontWeight="bold" sx={{ mt: 2, mb: 0.25 }}>
                Address:
              </Typography>
              <Typography>
                SQAIX<br />
                Lombriser & Co.<br />
                Soft- & Hardware Engineering<br />
                Oberweg 8<br />
                7203 Trimmis<br />
                Switzerland
              </Typography>
              <Typography fontWeight="bold" sx={{ mt: 2, mb: 0.25 }}>
                Legal Form:
              </Typography>
              <Typography>
                Limited Partnership (Kommanditgesellschaft)
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={evt => setImpressumOpen(false)}>OK</Button>
          </DialogActions>
        </Dialog>

      </Container>
    </ThemeProvider>
  );
}

export default App;
