import React, { useContext, useState } from 'react' 
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  useTheme,
} from "@mui/material";
import { Token } from '../../api';
import AuthContext from '../../context/AuthContext';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("")

  const { loginUser } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleLogin = () => {
    setError("")

    const token: Token = {access_token: "fake-token", token_type: "fake-token-type"}

    try {
      loginUser(token)
    }
    catch (error) {
      setError(`⚠️ ${error}`)
    }

    setError(`⚠️ Unauthorized`)
  };

  const handleCancel = () => {
    navigate("/")
  }

  const theme = useTheme();

  return (
    <>
      <Container maxWidth="xs">
        <Box
          sx={{
            mt: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >

          <Grid
            container
            justifyContent="center"
            sx = {{
              width: {xs: 315, sm: 400},
              mt: {xs: 7, sm: 4},
              mb: {xs: 7, sm: 4},
            }}
          >
            {theme.palette.mode === 'dark' ? (
                <img src="/images/logo-b.png" alt='logo-b' width="100%" />
            ) : (
                <img src="/images/logo-w.png" alt='logo-w' width="100%" />
            )}
          </Grid>
                    
          <Typography sx={{mt:4}}>
              Please login
          </Typography>

          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              type='email'
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />

            {error && <Typography color="red">{error}</Typography>}

          </Box>

        </Box>

        <Box
          gap={4}
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleLogin}
          >
            Login
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Box>

      </Container>      
    </>
  )
}

export default Login
